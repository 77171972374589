<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

        <a-row :gutter="24" class="px-15" style="margin-top: 0px;">
            <a-col :span="24" :md="16" :lg="16" :xl="16" class="mb-24">
                <a-badge 
                    v-for="option in filterItems"
                    :key="option.uuid"
                    style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
                    :count="`${option.name}    ${option.icon}`" 
                    @click="onSelectFilterItem(option)"
                    :number-style="pagination.filter == option.uuid ? 
                        {backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '5px', paddingRight: '18px', paddingLeft: '18px', height: '32px', fontSize: '14px'} : 
                        {border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: 'transparent !important', paddingTop: '5px', paddingRight: '18px', paddingLeft: '18px', height: '32px', fontSize: '14px'}"/>

                <a-button
                    id="textBtn"
                    type="text"
                    class="ml-15"
                    size="small"
                    @click="toCreateCategoriesPage"
                    style="border-radius: 20px;">
                    Manage Categories
                    <font-awesome-icon icon="fa-solid fa-gear" class="ml-10" style=""/>
                </a-button>
            </a-col>

			<a-col :span="24" :md="8" :lg="8" :xl="8" class="mt-10 text-right">
                <a-button
                    type="primary"
                    class="ml-15"
                    size="small"
                    @click="toCreateNotesPage"
                    style="border-radius: 20px;">
                    Publish New Notes
                    <a-icon type="plus" theme="outlined" />
                </a-button>
			</a-col>
		</a-row>

		<div v-if="!loadingData" class="mb-20 mt-20">

			<a-row :gutter="24" class="px-20">

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 pb-10">
					<a-card>
                        <a-row :gutter="24" class="mb-0 pb-0 pt-5 px-10">
                            <a-col :span="24" :md="14" :lg="14" :xl="14" class="mb-0 pb-0">
                                <h6 style="font-size: 18px;">{{ notes.length }} Notes</h6>
                            </a-col>
                            <a-col :span="24" :md="10" :lg="10" :xl="10" class="mb-0 pb-0 text-right">
                                <h6 style="font-weight: normal;"><small>TZS</small> {{ noteEarnings.toLocaleString() }}/=</h6>
                            </a-col>
                        </a-row>
                        
                        <a-row :gutter="24" class="mb-0 pb-0 mt-15">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0">
                                <a-table
                                    class="ant-table-striped"
                                    :columns="columns" 
									:data-source="notes"
									:loading="loadingData" 
									:pagination="true" 
									:rowKey="record => record.uuid"
									width="100%" 
									style="width: 100%;">

                                        <template slot="s_no" slot-scope="s_no, record, index">
                                            {{ (pagination.currentPage - 1) * pagination.perPage + index + 1 }}
                                        </template>

                                        <template slot="notesTitle" slot-scope="row"> 
                                            <a-avatar :src="row.thumbnail" shape="square" class="mr-5"/>
                                            {{ formatName(row.title.length > 35 ? `${row.title.substring(0, 35)}...` : row.title ) }}
                                        </template>

                                        <template slot="publisher" slot-scope="row"> 
                                            {{ row.creator != null ? `${row.creator.firstName} ${row.creator.lastName}` : 'Unknown' }}
                                        </template>

                                        <template slot="students" slot-scope="row"> 
                                            <!-- {{ row.studentNo }} -->
                                            {{ row.completed.length }}
                                        </template>

                                        <template slot="price" slot-scope="row"> 
                                            {{ row.price != null && row.price > 0 ? `${row.price.toLocaleString()}/=` : 'Free' }}
                                        </template>

                                        <template slot="action" slot-scope="row"> 
                                            <a-button
                                                id="textBtn"
                                                type="text"
                                                class="ml-15"
                                                size="small"
                                                @click="viewNote(row)"
                                                style="border-radius: 20px;">
                                                
                                                <a-icon type="eye" theme="outlined" />
                                            </a-button>
                                        </template>
                                        
                                </a-table>
                            </a-col>
                        </a-row>
                        
                    </a-card>
				</a-col>

			</a-row>

		</div>

		<div v-if="loadingData">

			<a-row type="flex" justify="space-around" align="middle" class="px-20" style="min-height: 60vh">

				<a-col :span="24" :md="24" :lg="24" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>

        <a-drawer
            title="Notes Details"
            placement="right"
            :closable="true"
            :visible="noteDetails.showDrawer"
            @close="onCloseDrawer"
            width="560">
            
            <a-row :gutter="24">
                <a-col :span="24">

                    <a-row :gutter="24">
                        <a-col :span="24">
                            <h5>{{ noteDetails.title }}</h5>
                        </a-col>

                        <a-col :span="24">
                            <img id="classImg" width="100%" height="250px" :src="noteDetails.thumbnail" alt="">
                        </a-col>
                    </a-row>
                    

                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Publisher</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ noteDetails.instructor }}
                        </a-col>
                    </a-row>


                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Education Level</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ noteDetails.educationLevel.name }}
                        </a-col>
                    </a-row>


                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Grades</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <a-badge 
                                v-for="option in noteDetails.grades"
                                :key="option.uuid"
                                style="font-size: 12px !important;" 
                                :count="`${option.name}`" 
                                :number-style="{backgroundColor: '#DBE2ED !important', color: '#414141', paddingTop: '2px', paddingRight: '10px', paddingLeft: '10px', height: '25px', fontSize: '12px'}"/>
                        </a-col>
                    </a-row>


                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Subjects</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <a-badge 
                                v-for="option in noteDetails.subjects"
                                :key="option.uuid"
                                style="font-size: 12px !important;" 
                                :count="`${option.name}`" 
                                :number-style="{backgroundColor: '#DBE2ED !important', color: '#414141', paddingTop: '2px', paddingRight: '10px', paddingLeft: '10px', height: '25px', fontSize: '12px'}"/>
                        </a-col>
                    </a-row>


                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Completion</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ noteDetails.completed }}
                        </a-col>
                    </a-row>

                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Price</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <span style="font-size: 10px; font-weight: 600;" v-if="noteDetails.price != 0 && noteDetails.price != null">TZS </span> {{ noteDetails.price != 0 && noteDetails.price != null ? noteDetails.price.toLocaleString() : 'Free' }}
                        </a-col>
                    </a-row>

                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Rating</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ noteDetails.totalRating }}
                        </a-col>
                    </a-row>

                    <!-- <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Label</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <a-button
                                id="textBtn"
                                type="text"
                                class="ml-15"
                                size="small"
                                @click="toCreateNotesPage"
                                style="border-radius: 20px;">
                                <a-icon type="plus" theme="outlined" />
                                Add
                            </a-button>
                            <a-select
                                v-model="noteDetails.label"
                                :size="size"
                                style="width: 100px;"
                                class="py-0"
                                :options="options">
                                <a-select-option v-for="option in categories"
                                    :value="option.uuid"
                                    :key="option.name">
                                    {{ option.name }}
                                </a-select-option>
                            </a-select>
                        </a-col>
                    </a-row> -->

                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Uploaded Date</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ noteDetails.createdAt }}
                        </a-col>
                    </a-row>


                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Category</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <a-button
                                id="textBtn"
                                type="text"
                                class="ml-15"
                                size="small"
                                v-if="noteDetails.category == null"
                                @click="onAddingCategory"
                                style="border-radius: 20px;">
                                <a-icon type="plus" theme="outlined" />
                                {{ 'Add' }}
                            </a-button>

                            <a-button
                                id="textBtn"
                                type="text"
                                class="ml-15"
                                size="small"
                                v-if="noteDetails.category != null"
                                @click="onEditingCategory"
                                style="border-radius: 20px;">
                                <a-icon type="edit" theme="outlined" />
                                {{ 'Edit' }}
                            </a-button>
                            
                            <a-badge 
                                v-if="noteDetails.category != null"
                                style="font-size: 12px !important;" 
                                :count="`${noteDetails.category.name}`" 
                                :number-style="{backgroundColor: '#DBE2ED !important', color: '#414141', paddingTop: '2px', paddingRight: '10px', paddingLeft: '10px', height: '25px', fontSize: '12px'}"/>
                        </a-col>
                    </a-row>

                    <a-row :gutter="24" class="mt-20 pt-20 px-10">
                        <a-col :span="24" class="text-center mt-20">
                            <a-button
                                type="primary"
                                class="ml-15"
                                size="small"
                                @click="toEditNotesPage"
                                style="border-radius: 20px;">
                                <a-icon type="edit" theme="outlined" />
                                Edit Details 
                            </a-button>
                        </a-col>
                    </a-row>
                    

                </a-col>
            </a-row>
        </a-drawer>



         <!-- FORM MODAL -->
		<a-modal v-model="category.showModal" :footer="null" :width="480">
			<h4 class="text-center">{{  category.isCreate ? 'Add Quiz Category' : 'Edit Quiz Category'}}</h4>
			<a-form-model
				class="login-form"
				@submit.prevent="submitCategory"
				:hideRequiredMark="true">

				<a-form-item class="mb-10" label="Category" :colon="false">
					<a-select 
                        v-model="category.category"
                        style="width: 100%;" 
                        placeholder="Select Category">
                        <a-select-option v-for="option in categories"
                            :value="option.uuid"
                            :key="option.uuid">
                            {{ option.name }}
                        </a-select-option>
                    </a-select>
				</a-form-item>
                

				<a-form-item>
					<a-button type="primary" :disabled="category.category == null" :loading="category.loading" block html-type="submit" class="login-form-button text-white mt-20">
						{{ category.isCreate ? 'Save' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form-model>
			
		</a-modal>

	</div>
</template>

<script>
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter';
    import { notification } from 'ant-design-vue';


    const columns = [
        {
            title: 'S/N',
            scopedSlots: { customRender: 's_no' },
            width: 80
        },
        {
            title: 'Notes title',
            scopedSlots: { customRender: 'notesTitle' },
            width: 200
        },
        {
            title: 'Publisher',
            scopedSlots: { customRender: 'publisher' },
            width: 200
        },
        {
            title: '# of Completion',
            scopedSlots: { customRender: 'students' },
            key: 'sudents',
            width: 130
        },
        {
            title: 'Price',
            scopedSlots: { customRender: 'price' },
            width: 100
        },
        {
            title: 'View Class',
            scopedSlots: { customRender: 'action' },
            width: 100
        },
    ];

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,

				notes: [],
                categories: [],
                noteEarnings: 0,
                columns,
				currentPage: 1,
                pagination: {
					search: '',
                    filter: 'my-notes',
                    filterType: 'static',
                    perPage: 13,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

				slider: {
                    max: 40000,
                    min: 0,
                    step: 500
                },

                filterItems: [
                    { uuid: 'my-notes', name: 'All Notes ', icon: ' 🎓', type: 'static'},
                    { uuid: 'archived', name: 'Archived ', icon: ' 🗃️', type: 'static'}
                ],

                filter: {
                    loading: false,
                    showModal: false,
                    isActive: false,
                    type: null,
                    price: 20000,
                    tutorRating: 3,
                    startTime: null,
                },

                noteDetails: {
                    uuid: null,
                    title: null,
                    thumbnail: null,
                    instructor: null,
                    createdAt: null,
                    members: null,
                    completed: null,
                    price: null,
                    educationLevel: {},
                    educationSystem: {},
                    categorry: {},
                    subjects: [],
                    grades: [],
                    totalRevenue: null,
                    totalRating: null,
                    label: null,
                    showDrawer: false,
                },

                category: {
                    category: null,
                    showModal: false,
                    isCreate: true,
                    loading:false,
                },
      		}
		},
		watch: {
            // pagination: {
				
            //     handler() {
            //         // this.getInstitutionNotes();
			// 		console.log(this.pagination.currentPage)
            //     },
            // },
			currentPage: {
                handler() {
					console.log(this.currentPage)
					this.pagination.currentPage = this.currentPage;
					this.getInstitutionNotes(this.educationLevel);
                },
            },
            deep: true,
        },
		mounted () {
			let breadcrumbs = [
				{ title: 'Notes', url: '/notes', isActive: true }
			];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
            this.$root.$refs.InstitutionNotes = this;
            this.initializeFilter();
            this.getCategories();
            this.getInstitutionNotes();
		},
		methods: {

			formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

            toEditNotesPage() {
                this.$router.push(`/institutions/my-notes/${this.noteDetails.uuid}/edit`)
            },

            toCreateNotesPage() {
                this.$router.push('/institutions/my-notes/publish-new-notes')
            },

            async toCreateCategoriesPage() {
                await localStorage.setItem('previous_route', this.$route.path);

                this.$router.push('/institutions/categories')
            },

			submitSearch(search) {
                this.pagination.search = search;
				this.pagination.currentPage = 1;
				this.getInstitutionNotes();
			},

			cancelSearch() {
				this.pagination.search = '';
				this.getInstitutionNotes(this.educationLevel);
			},

			launchFilter() {
                this.filter.showModal = true;
            },

            clearFilter() {
                this.filter.isActive = false;
                this.filter.showModal = false;
                this.getInstitutionNotes(this.educationLevel);
            },

            submitFilter() {
                this.filter.isActive = true;
                this.filter.showModal = false;
                this.getInstitutionNotes();
            },

            async initializeFilter() {
                let filter = JSON.stringify(await localStorage.getItem('filter'))

                if(filter != null) {
                    if(filter.navigate == true) {
                        filter.navigate = false;
                        await localStorage.setItem('filter', JSON.stringify(filter))
                        
                        this.filter.price = filter.price;
                        this.filter.tutorRating = filter.tutorRating;
                        this.filter.startTime = filter.startTime;
                    }
                }
            },

            onSelectFilterItem(item) {
                this.pagination.filter = item.uuid;
                this.pagination.filterType = item.type;

                this.getInstitutionNotes();
            },


            async getInstitutionNotes() {

				this.loadingData = true;

                let url = '';

                let institutionInfo = await localStorage.getItem("institution_details")

				let institution = institutionInfo != null ? JSON.parse(institutionInfo) : {}

                if(this.pagination.filterType == 'custom') {

                    const { currentPage, perPage, search } = this.pagination;

                    if(search != null && search.length > 0) {                       
                        url = `${this.$BACKEND_URL}/materials?institution=${institution.uuid}&category=${this.pagination.filter}&search=${search}&page=${currentPage}&size=${perPage}&populate=true`;
                    }else{
                        url = `${this.$BACKEND_URL}/materials?institution=${institution.uuid}&category=${this.pagination.filter}&page=${currentPage}&size=${perPage}&populate=true`;
                    }
                }


                if(this.pagination.filter == 'my-notes') {

                    const { currentPage, perPage, search } = this.pagination;

                    if(search != null && search.length > 0) {                       
                        url = `${this.$BACKEND_URL}/materials?institution=${institution.uuid}&search=${search}&page=${currentPage}&size=${perPage}&populate=true`;
                    }else{
                        url = `${this.$BACKEND_URL}/materials?institution=${institution.uuid}&page=${currentPage}&size=${perPage}&populate=true`;
                    }
                }


                if(this.pagination.filter == 'archived') {

                    const currentPage = 1;
                    const perPage = 1000;
                    let { search } = this.pagination;

                    if(search != null && search.length > 0) {
                        url = `${this.$BACKEND_URL}/materials/archived?institution=${institution.uuid}&search=${search}&page=${currentPage}&size=${perPage}&populate=true`;
                    }else{
                        url = `${this.$BACKEND_URL}/materials/archived?institution=${institution.uuid}&page=${currentPage}&size=${perPage}&populate=true`;
                    }
                }
                

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.notes = response.data.data;
						this.pagination.totalPages = response.data.pagination != null ? response.data.pagination.totalPages : 1;
                        this.pagination.total = response.data.pagination != null ? response.data.pagination.totalItems : 0;
                        this.pagination.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
						this.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
                    }

                    this.getNotesReport(institution.uuid);
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async getCategories() {

				this.loadingData = true;

                let institutionInfo = await localStorage.getItem("institution_details")

				let institution = institutionInfo != null ? JSON.parse(institutionInfo) : {}

                this.institution = institution;

                const currentPage = 1
                const perPage = 1000;
                  
                let url = `${this.$BACKEND_URL}/categories?creator=${institution.uuid}&page=${currentPage}&size=${perPage}`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
                        
                        this.categories = response.data.data;

						response.data.data.forEach(category => {
                            category.icon = ' 🧩';
                            category.type = 'custom'
                            this.filterItems.push(category)
                        })
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async getNotesReport(institution) {

				this.loadingData = true;

                let url = `${this.$BACKEND_URL}/payments/reports?populate=true&uuid=${institution}&isForInstitution=true`;
                
                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
                        if(response.data.data != null && response.data.data.length > 0) {
                            response.data.data.forEach(element => {

                                if(element.price != null) {
                                    this.totalEarning += element.price;
                                }

                                if(element.isMaterial && element.price != null) {
                                    this.noteEarnings += element.price;
                                }
                            });
                        }
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            onAddingCategory() {
                this.category.category = undefined;
                this.category.loading = false;
                this.category.showModal = true;
            },

            onEditingCategory() {
                this.category.category = this.noteDetails.category.uuid;
                this.category.isCreate = false;
                this.category.loading = false;
                this.category.showModal = true;
            },


            submitCategory() {

                this.category.loading = true;

				let url = `${this.$BACKEND_URL}/materials/${this.noteDetails.uuid}`;

				this.$AXIOS.patch(url, this.category).then(async(response) => {

					this.notify('Category have been saved successfully', 'success')

					this.category.showModal = false;
                    this.category.loading = false;

                    this.onCloseDrawer();

                    this.getInstitutionNotes();

				}).catch(async(error) => {

					this.category.loading = false;

                    console.log(error)

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});
            },

            viewNote(note) {
                this.noteDetails.uuid = note.uuid;
                this.noteDetails.title = note.title;
                this.noteDetails.thumbnail = note.thumbnail;
                this.noteDetails.instructor = note.creator != null ? `${note.creator.firstName} ${note.creator.lastName}` : 'Unknown';
                this.noteDetails.createdAt = note.createdAt != null ? this.$Moment(note.createdAt).format('DD MMM YYYY') : note.createdAt;
                this.noteDetails.educationSystem = note.educationSystems.length > 0 ? note.educationSystems[0] : {};
                this.noteDetails.educationLevel = note.educationLevels.length > 0 ? note.educationLevels[0] : {};
                this.noteDetails.grades = note.educationGrades;
                this.noteDetails.subjects = note.subjects;
                this.noteDetails.members = note.completed;
                this.noteDetails.completed = note.completed.length;
                this.noteDetails.price = note.price;
                this.noteDetails.category = note.category;
                this.noteDetails.totalRevenue = note.price;
                this.noteDetails.totalRating = note.rating != null ? note.rating : 5;
                this.noteDetails.label = null
                this.noteDetails.showDrawer = true;
            },

            onCloseDrawer() {
                this.noteDetails.showDrawer = false;
                this.noteDetails.uuid = null;
                this.noteDetails.title = null;
                this.noteDetails.thumbnail = null;
                this.noteDetails.instructor = null;
                this.noteDetails.createdAt = null;
                this.noteDetails.members = null;
                this.noteDetails.completed = null;
                this.noteDetails.price = null;
                this.noteDetails.totalRevenue = null;
                this.noteDetails.totalRating = null;
                this.noteDetails.label = null;
            },


            notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	border-radius: 5px;
}

a:hover {
    color: inherit !important;
}
</style>